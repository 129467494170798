import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import styled, { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'common/src/containers/Saas/saas.style';
import Navbar from 'common/src/containers/Saas/Navbar';
import Footer from 'common/src/containers/Saas/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';

import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Text from 'reusecore/src/elements/Text';
import Container from 'common/src/components/UI/Container';

import { graphql } from 'gatsby';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO
          title={frontmatter.title}
          description={frontmatter.metadescription}
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Wrapper>
            <Container>
              <Box mb="56px">
                <Heading
                  {...{
                    as: 'h1',
                    textAlign: 'center',
                    fontSize: ['20px', '24px'],
                    color: '#0f2137',
                    letterSpacing: '-0.025em',
                  }}
                  content={frontmatter.title}
                />
                <Text
                  {...{
                    as: 'span',
                    display: 'block',
                    textAlign: 'center',
                    fontSize: '14px',
                    // letterSpacing: '0.15em',
                    fontWeight: '700',
                    color: '#5268db',
                    mb: '5px',
                  }}
                  content={frontmatter.subtitle}
                />
              </Box>
              <Box className="row">
                <TextWrapper>
                  <div dangerouslySetInnerHTML={{ __html: html }} />
                </TextWrapper>
              </Box>
            </Container>
          </Wrapper>
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
}

const TextWrapper = styled.div`
  ol li {
    list-style-type: decimal;
  }
  ul {
    margin: auto;
    padding-inline-start: 40px;
    li {
      list-style-type: disc;
    }
  }
`;

const Wrapper = styled.section`
  padding: 104px 0;
  background: #fafbff;
`;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        subtitle
        metadescription
      }
    }
  }
`;
